import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules';

import '../assets/styles/Acervo.sass'


const bairros = [
    {
        bairro: "Leblon", desc_curta: "Um lugar só seu no coração do Leblon", metragem: "Experiências de 135m² a 569m²", image: "./Home/Leblon.jpg",
        desc: "Celebrar a vida à beira-mar, a luz solar e o dom de ser feliz: seja bem-vindo ao Leblon. Aqui, os pés tocam a areia e a maresia gentilmente recorda que a vida corre no momento presente. Fazer morada no Leblon é enfim entender todas as milhares de poesias e canções escritas em homenagem ao Rio, e eleger a inspiração como rotina. Esteja perto de tudo e caminhe com fluidez entre a natureza, boemia e gastronomia, na máxima expressão da alma carioca traduzida da arquitetura às entrelinhas de cada esquina"
    },
    {
        bairro: "Ipanema", desc_curta: "Mudando o significado da palavra rotina", metragem: "Experiências a partir de 143m²", image: "./Home/Ipanema.jpg",
        desc: "Entre a areia e o mar, Ipanema é o convite à essência solar carioca. O privilégio de caminhar entre as charmosas ruas do bairro é descobrir a beleza do Rio como quintal. Viva a rotina do amanhecer na praia e o entardecer no bar brindando à vida com amigos, ou explorando um novo restaurante internacional no efervescente polo gastronômico da região. Ipanema é a eterna e sorte de morar na cidade maravilhosa"
    },
    {
        bairro: "Lagoa", desc_curta: "Seja bem-vindo ao tom perfeito para a sua vida.", metragem: "Experiências a partir de 138m²", image: "./Home/Lagoa.jpg",
        desc: "Considerada por muitos um dos cartões postais mais inesquecíveis do Rio, a Lagoa Rodrigo de Freitas atrai e encanta milhares de admiradores ao redor do mundo. Experimente viver  ao ar livre e ter a beleza natural da cidade como sua inspiração diária. E para os amantes da alta gastronomia, a Lagoa nos brinda com seu renomado polo gastronômico, num convite a desfrutar do melhor da vida carioca"
    },
    {
        bairro: "Jardim Botânico", desc_curta: "O local ideal para viver com comodidade e praticidade.", metragem: "Experiências de 41m² a 92m²", image: "./Home/JB.jpg",
        desc: "Sinta o verde das montanhas e o clima agradável do bairro te envolverem e inspirarem. O Jardim Botânico, dotado da vocação natural de um refúgio urbano em pleno Rio de Janeiro, une sua beleza bucólica à localização estratégica, se tornando, assim, o endereço ideal para quem busca a tranquilidade sem abrir mão da comodidade na rotina"
    },
    {
        bairro: "Gávea", desc_curta: "Viva a experiência de uma vida mais leve e a sensação de estar de férias o ano inteiro", metragem: "Experiências a partir de 27m² a 179m²", image: "./Home/Gávea.jpg",
        desc: "Na Gávea, a contemplação da alma natural carioca encontra aqui a fusão singular com o Parque Nacional da Tijuca, trazendo a (re)conexão entre cidade e floresta. É o convite a  ressignificar os sentidos, a rotina e a vida, transbordando inspiração na construção de memórias inesquecíveis. Viva a experiência única de estar cercado por uma paisagem verde de perder o fôlego e ainda assim apenas 10 minutos de distância da Praia do Leblon "
    },
    // {
    //   bairro: "Botafogo", desc_curta: "Onde a arte e arquitetura se unem e inspiram a contemplação do Rio", metragem: "Experiências a partir de 104m²", image: "./Home/Botafogo.jpg",
    //   desc: "Onde o novo convive com o passado e assim são construídas novas histórias. Botafogo é a síntese da pluralidade e profusão entre arte, gastronomia e efervescência. Explore teatros, cinemas e a cena cultural vibrante do bairro, ou escolha entre centenas de bares e restaurantes com experiências gastronômicas do mundo todo. Seu novo endereço é a experiência de viver todas as facetas do Rio um só lugar, desfrutando de uma das localizações mais estratégica da cidade"
    // }
]

function Acervo() {

    function deactiveCard(target: any) {
        const cardElements = document.getElementsByClassName('card-bairro');
        for (let i = 0; i < cardElements.length; i++) {
            const element = cardElements[i];
            if (element !== target) {
                element.classList.add('unmarked');
            }
        }
    }

    function activeCard() {
        const cardElements = document.getElementsByClassName('card-bairro');
        for (let i = 0; i < cardElements.length; i++) {
            cardElements[i].classList.remove('unmarked');
        }
    }

    return (
        <section id='acervo' className='bg-white h-auto'>

            <div className='title'>
                Procurando um novo residencial Mozak para
                <div>
                    <span className='frases font-quicksand font-extrabold'></span>
                </div>
            </div>

            <div className='content'>
                {bairros.map((bairro, i) => (
                    <div key={i} className='card-bairro' onMouseEnter={(event) => deactiveCard(event.currentTarget)} onMouseLeave={activeCard} onClick={() => window.open('https://acervomozak.com.br/')}>
                        <img src={bairro.image} alt='' />
                        <h2>{bairro.bairro}</h2>
                        <p>{bairro.desc_curta}</p>
                        <p>{bairro.metragem}</p>
                        <a>Ver mais</a>
                    </div>
                ))}
            </div>
            <div className='content-mobile'>
                <Swiper slidesPerView={1} centeredSlides navigation={true} modules={[Navigation]}>
                    {bairros.map((bairro, i) => (
                        <SwiperSlide key={i}>
                            <div className='card-bairro' onClick={() => window.open('https://acervomozak.com.br/')}>
                                <img src={bairro.image} alt='' />
                                <h2>{bairro.bairro}</h2>
                                <p>{bairro.desc_curta}</p>
                                <p>{bairro.metragem}</p>
                                <a>Ver mais</a>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
}

export default Acervo;