import React from 'react';

function Footer() {
    return (
        <footer className='bg-blue flex flex-col items-center justify-center py-5'>
            <span className='font-light text-white text-2xl'>Realização:</span>
            <img src='./img/mozak.png' alt='Mozak' className='w-[200px] mt-[33px]' />
            <span className='text-white text-[.8rem] mt-[33px]'>CRECI RJ-008658/O</span>
        </footer>
    );
}

export default Footer;