import React from 'react';

function Hero() {
    return (
        <section id="hero" className='h-[70vh] md:h-[90vh] overflow-hidden relative flex flex-col items-center justify-center pt-20 2xl:pt-32 text-white'>
            <div className='flex flex-col items-center'>
                <img src="./img/logo_branca.png" alt='Era Botafogo' className='drop-shadow-xl w-[200px] md:w-[300px] 2xl:w-[300px]' />
            </div>
            <div className='flex flex-col items-center mt-10 md:mt-20 2xl:mt-32 drop-shadow-xl'>
                <h1 className=' text-4xl 2xl:text-6xl'>100% VENDIDO</h1>
                <p className=' font-light text-md md:text-xl 2xl:text-2xl bg-blue px-2 py-1 rounded-sm'>Obrigado por fazer parte deste sucesso</p>
            </div>
        </section>
    );
}

export default Hero;