import React from 'react';
import Hero from './components/Hero';
import Menu from './components/Menu';
import Acervo from './components/Acervo';
import Footer from './components/Footer';
import AMozak from './components/AMozak';


function App() {
  return (
    <div className="App">
      <Menu />
      <Hero />
      <Acervo />
      <AMozak />
      <Footer />
    </div>
  );
}

export default App;
